.body-login {
    background: #007bff;
    background: linear-gradient(to right, #0062e6, #33aeff);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-image: url(../../assets/img/iglesia.jpg);
    height: 100%;
}

.card-signin {
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.54);
}