html, body, #root {
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
}

.primary, .fa-play {
    color: #33AEFF;
}

.bg-primary {
    background-color: #33AEFF;
}

a, a:hover {
    color: #33AEFF;
    text-decoration: none;
    background-color: transparent;
}

.MuiOutlinedInput-root {
    border-radius: 30px !important;
}
.swal2-styled.swal2-confirm {
    background-color: #33AEFF;
}
    .swal2-styled.swal2-confirm:focus, button:focus {
        box-shadow: 0 0 0 3px rgb(1 154 255 / 33%);
        outline:none !important;
    }

.MuiBottomNavigationAction-root{
    min-width:0px !important;
}

.b-0{
    border-radius:0px !important;
}

.swal2-container{
    z-index:100000000000 !important;
}