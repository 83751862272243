.player {
    width: 100%;
}

.title {
    color: #333;
    font-size: 0.8571428571428571rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.novolume .progressbar {
    right: 4.375em;
}

.progressbar {
    background-color: #fff;
    cursor: pointer;
    z-index: 1;
    left: 0px;
    /* position: absolute; */
    width: 100%;
    /* top: 100px; */
    margin-bottom: 20px;
}

.novolume .progressbar {
    right: 4.375em;
}

.progressbar div {
    border-radius: 50px;
    width: 0%;
    height: 15px;
    position: absolute;
    left: 0;
    top: 0;
}

.bar-loaded {
    background-color: #eee;
    z-index: 1;
}

.bar-played {
    background: #33AEFF;
    z-index: 2;
}

.playlist {
    list-style-type: none;
    width: 100%;
    padding: 0px;
    margin: 0px;
}

    .playlist li,
    .playlist div {
        cursor: pointer;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        padding-right: 16px;
        padding-left: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        justify-content: flex-start;
        text-decoration: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

        .playlist li > a,
        .playlist div > a {
            color: #333;
            text-decoration: none;
            flex: 1 1 auto;
            min-width: 0;
            margin-top: 4px;
            margin-bottom: 4px;
            font-size: 0.8571428571428571rem;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0.00938em;
        }

        .playlist li.active,
        .playlist div.active,
        .playlist li.active a {
            color: #33AEFF !important;
        }


            .playlist li.active::before {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: "\f028";
            }

.icon-font {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

li.playList-item.active a:first-child::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f028";
}

.playlist li.active::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f028";
    margin-right:5px;
}

.icon-font {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

li.playList-item.active a:first-child::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f028";
}

.mute .volume-btn {
    /*background: url(reproductor/volume-off.svg) no-repeat;*/
}

.volume-btn {
    /*background: url(reproductor/volume-up.svg) no-repeat;*/
    height: 20px;
    width: 20px;
    float: left;
    position: relative;
    top: -4px;
}

.row {
    flex-wrap: unset;
}

.controls .rew,
.controls .fwd,
.controls .stop,
.controls .shuffle,
.controls .read,
.controls .play,
.controls .pause {
    width: 50px;
    height: 50px;
    margin: 5px;
}

.controls .play {
    background: url(reproductor/play.svg) no-repeat;
}

.controls .shuffle {
    background: url(reproductor/shuffle.svg) no-repeat;
}

.controls .read {
    background: url(reproductor/read.svg) no-repeat;
}

.fa-readme:before {
    display: none !important;
}

.controls .pause {
    background: url(reproductor/pause.svg) no-repeat;
    display: none;
}

.controls .rew {
    background: url(reproductor/rewind.svg) no-repeat;
}

.controls .fwd {
    background: url(./reproductor/next.svg) no-repeat;
}

.controls .stop {
    background: url(reproductor/stop.svg) no-repeat;
}

.hidden {
    display: none;
}

.controls .visible {
    display: block;
}


.timeHolder {
    color: #ffffff;
    font-size: 14px;
    width: 100%;
}

.time-current,
.time-duration,
.time-separator {
    color: #333;
    font-size: 14px;
    display: inline-block;
}

.artist, .volume, .stop {
    visibility: hidden;
    display: none;
}

.fixed-player{
    background-color:#fff;
    padding-top:25px;
}